export const GRAINBIN_ID = 17;
export const TRUCK_ID = 9;
export const TRACTOR_ID = 2;
export const DEFAULT_ALERT_SEARCH_DAYS = 30;
export const IOS_1 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/iOS_Instructions/ios1.jpg";
export const IOS_2 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/iOS_Instructions/ios2.jpg";
export const IOS_3 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/iOS_Instructions/ios3.jpg";
export const ANDROID_1 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/Android_Instructions/android1.jpg";
export const ANDROID_2 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/Android_Instructions/android2.jpg";
export const ANDROID_3 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/Android_Instructions/android3.jpg";
export const IOS_GPS_1 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/iOS_Instructions/ios_gps_1.png";
export const IOS_GPS_2 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/iOS_Instructions/ios_gps_2.png";
export const IOS_GPS_3 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/iOS_Instructions/ios_gps_3.png";

export const ANDROID_GPS_1 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/Android_Instructions/android_gps_1.jpeg";
export const ANDROID_GPS_2 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/Android_Instructions/android_gps_2.jpeg";
export const ANDROID_GPS_3 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/Android_Instructions/android_gps_3.jpeg";
export const ANDROID_GPS_4 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/Android_Instructions/android_gps_4.jpeg";
export const ANDROID_GPS_5 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/Android_Instructions/android_gps_5.jpeg";
export const ANDROID_GPS_6 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/Android_Instructions/android_gps_6.jpeg";
export const ANDROID_GPS_7 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/Android_Instructions/android_gps_7.jpeg";

export const ANDROID_APK_LINK =
  "https://drive.google.com/file/d/1sInfIfsbP0OLyi7Trn76-mvmt3h0O0gQ/view?usp=sharing";

export const ALERT_TIMER = 2000;
export const SYSTEM_ADMIN = "ROLE_system admin";
export const FARM_ADMIN = "ROLE_admin";
export const INSPECTION_MANAGER = "ROLE_inspection manager";
export const DEFAULT_DAYS_FILTER = 30;
export const NON_FARM_DASHBOARD = "NON-FARM DASHBOARD";
export const ALERT_HIGHLIGHT_TIMER = 5000;
export const DEFAULT_DATAGRID_PAGE_SIZE = 100;
export const CROP_PROCESSOR = "ROLE_crop processors";
export const CONTRIBUTOR = "ROLE_contributor";
export const DEFAULT_DIAGNOSTIC_LEVEL = 9;
export const TWOS_COMPLEMENT_CONSTANT = 256;
export const COORDINATES_REGEX =
  /^-?\d+(\.\d+)?(\|\s?|-?\s?|\t)\s?-?\d+(\.\d+)?$/;
export const MAINTENANCE_TYPE_CLEANING = "cleaning";
export const CROP_AREA_REGEX = /^[1-9]\d*(\.\d+)?$/;
export const SD_REWIND_POINTER_CMD_MSG = "6";
export const FARMIP_SCANNER_FIRMWARE = "farmip-scanner-firmware";
export const FARMIP_SCANNER_FIRMWARE_BETA = "farmip-scanner-firmware-beta";
export const DEFAULT_DLF_TRANSFER_TIME = 10;
export const DEFAULT_DLF_CHANNEL = "ALL";
export const S3_FARMS_FILE_BUCKET = "farmip-farms";
export const FIELDS_OBJECT_POSTFIX = "/fields.txt";
export const ACTIVITY_STATUS_PROGRESS = "PROGRESS";
export const S3_PREFIX = "https://farmip-server.s3.us-west-1.amazonaws.com/";
export const ON_DUTY_INSPECTION_MANAGER_CHECK_TIME = 120000;
export const WORKING_FIELD_ACTIVITY_ALT_NAMES =
  "field work (harrowing, tilling, rock-rolling, digging, cultivating, etc.)";

export const ANDROID_BACKGROUND_LOCATION_1 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/Android_Instructions/ANDROID_BACKGROUND_LOCATION_1.jpeg";
export const ANDROID_BACKGROUND_LOCATION_2 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/Android_Instructions/ANDROID_BACKGROUND_LOCATION_2.jpeg";
export const ANDROID_BACKGROUND_LOCATION_3 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/Android_Instructions/ANDROID_BACKGROUND_LOCATION_3.jpeg";
export const ANDROID_BACKGROUND_LOCATION_4 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/Android_Instructions/ANDROID_BACKGROUND_LOCATION_4.jpeg";
export const ANDROID_BACKGROUND_LOCATION_5 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/Android_Instructions/ANDROID_BACKGROUND_LOCATION_5.jpeg";
export const ANDROID_BACKGROUND_LOCATION_6 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/Android_Instructions/ANDROID_BACKGROUND_LOCATION_6.jpeg";
export const ANDROID_BACKGROUND_LOCATION_7 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/Android_Instructions/ANDROID_BACKGROUND_LOCATION_7.jpeg";
export const IOS_BACKGROUND_LOCATION_1 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/Android_Instructions/IOS_BACKGROUND_LOCATION_1.jpeg";
export const IOS_BACKGROUND_LOCATION_2 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/Android_Instructions/IOS_BACKGROUND_LOCATION_2.jpeg";
export const IOS_BACKGROUND_LOCATION_3 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/Android_Instructions/IOS_BACKGROUND_LOCATION_3.jpeg";
export const IOS_BACKGROUND_LOCATION_4 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/Android_Instructions/IOS_BACKGROUND_LOCATION_4.jpeg";
export const IOS_BACKGROUND_LOCATION_5 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/Android_Instructions/IOS_BACKGROUND_LOCATION_5.jpeg";
export const IOS_BACKGROUND_LOCATION_6 =
  "https://farmip-server.s3.us-west-1.amazonaws.com/Android_Instructions/IOS_BACKGROUND_LOCATION_6.jpeg";
export const COORDINATES_FORMAT_REGEX = /^-?\d+(\.\d+)?[|\s\t]-?\d+(\.\d+)?$/;
