class UserDetails {
  id;
  firstName;
  lastName;
  email;
  phone;

  constructor(id, firstName, lastName, email, phone) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phone = phone;
  }
}
export default UserDetails;
