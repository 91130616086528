import React from "react";

class TrainingAPI extends React.Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("token");
    this.headers = {
      "content-type": "application/json",
      accept: "application/json",
      "access-Control-Allow-Origin": "*",
      authorization: "Bearer " + this.token,
    };
  }

  //@GET
  //Get the User Exams
  async getAllUserExamsNotCleared() {
    const response = await fetch(`${process.env.REACT_APP_URL}/api/training-model/user-exam-failed`, {
      method: "GET",
      headers: this.headers,
    });
    const examList = await response.json();
    return examList;
  }

  //@GET
  //Get the User Exams
  async getAllUserExamsCleared() {
    const response = await fetch(`${process.env.REACT_APP_URL}/api/training-model/user-exam-passed`, {
      method: "GET",
      headers: this.headers,
    });
    const examList = await response.json();
    return examList;
  }
  //@GET
  //Get the User Exams
  async getUserExamById(id) {
    const response = await fetch(`${process.env.REACT_APP_URL}/api/training-model/user-exam/${id}`, {
      method: "GET",
      headers: this.headers,
    });
    const exam = await response.json();
    return exam;
  }

  //@GET
  //Get the User Exams
  async getAllQuestionsWithAnswers(id) {
    const response = await fetch(`${process.env.REACT_APP_URL}/api/training-model/exam/${id}`, {
      method: "GET",
      headers: this.headers,
    });
    return response.json();
  }

  //@PUT
  //Edit a facility
  async startUserExam(id) {
    const response = await fetch(`${process.env.REACT_APP_URL}/api/training-model/user-exam-start/${id}`, {
      method: "PUT",
      headers: this.headers
    });
    let responseJson = await response.json();
    let combinedResponse = { response: response, responseJson: responseJson };
    return combinedResponse;
  }

  //@PUT
  //Edit a facility
  async endUserExam(id, data) {
    const response = await fetch(`${process.env.REACT_APP_URL}/api/training-model/user-exam-end/${id}`, {
      method: "PUT",
      headers: this.headers,
      "body": JSON.stringify(data)
    });
    let responseJson = await response.json();
    let combinedResponse = { response: response, responseJson: responseJson };
    return combinedResponse;
  }

}

export default TrainingAPI;