import React from "react";
import "./TrainingModule.css";
import QuizImage from "../../Images/QuizImage.webp";
import TrainingAPI from "../../api/TrainingAPI";
import ExpiredToken from "../ExpiredToken";
import { withRouter } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import { Grid } from "@material-ui/core";

class TrainingPanel extends React.Component {
  expiredToken;
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("token");
    this.expiredToken = new ExpiredToken();
    this.trainingAPI = new TrainingAPI();
    this.handleExamChange = this.handleExamChange.bind(this);
    this.submit = this.submit.bind(this);
    this.state = {
      userExams: [],
      examValue: [],
      showExamQuiz: false,
      userExamsCleared: [],
      isExamSelected: false,
    };
  }

  componentDidMount() {
    this.expiredToken.checkTokenExpiration();
    this.getAllUserExamsNotCleared();
    this.getAllUserExamsCleared();
  }

  async getAllUserExamsNotCleared() {
    await this.trainingAPI.getAllUserExamsNotCleared().then((response) => {
      let myExams = [];
      if (response !== undefined) {
        if (response.userExamResponseDtoList !== null) {
          Object.values(response.userExamResponseDtoList).forEach((exam) => {
            myExams.push({
              value: exam.id,
              label: exam.examName,
            });
          });
        }
        this.setState({ userExams: myExams });
      }
    });
  }

  async getAllUserExamsCleared() {
    await this.trainingAPI.getAllUserExamsCleared().then((response) => {
      let myPassedExams = [];
      if (response !== undefined) {
        if (response.userExamResponseDtoList !== null) {
          Object.values(response.userExamResponseDtoList).forEach((exam) => {
            myPassedExams.push({
              examName: exam.examName,
            });
          });
        }
        this.setState({ userExamsCleared: myPassedExams });
      }
    });
  }
  handleExamChange(e) {
    this.setState({
      examValue: e.target.value,
      isExamSelected: true,
    });
  }

  submit(_e) {
    this.setState({
      showExamQuiz: true,
    });
  }

  render() {
    let disabled = this.state.examValue === "";
    return (
      <div className={
        isMobile
          ? "main-section-mobile"
          : this.props.showSidePanel
          ? "main-section"
          : "expand-main-section"
      }>
        {!isMobile || isTablet ? (
          <div>
            <img
              class="training-bg-image"
              src={QuizImage}
              alt="training_back_img"
            ></img>
            <div class="centered">
              WELCOME TO THE GRAINTRAK TRAINING MODULE!
            </div>{" "}
          </div>
        ) : (
          <Grid container className="page-title-css-training">
            <Grid item xs={8}>
              <span className="page_title_span_title_training">
                Welcome to The Graintrak training Module!
              </span>
            </Grid>
          </Grid>
        )}
        <div class="training-div-2">
          <div className="mb-3" />
          <div className="mb-3">
            <label
              for="exampleFormControlInput1"
              className="form-label exam-label"
            >
              Exams Not Cleared :
              <select
                required
                className="training_form_select1"
                name="Exams"
                onChange={(e) => this.handleExamChange(e)}
              >
                <option value="" disabled selected>
                  Select your Exam
                </option>
                {this.state.userExams.map((exam) => (
                  <option value={exam.value}>{exam.label}</option>
                ))}
              </select>
            </label>
            <button
              type="button"
              className={`training-btn1 ${
                this.state.isExamSelected ? "" : "disabled"
              }`}
              onClick={(e) => this.submit(e)}
              disabled={!this.state.isExamSelected}
            >
              {" "}
              Get Started
            </button>
          </div>
          <div className="mb-3">
            <label
              for="exampleFormControlInput1"
              className="form-label exam-label"
            >
              Exams Cleared :{" "}
              {this.state.userExamsCleared.length === 0
                ? "No exam cleared!"
                : this.state.userExamsCleared.map(
                    exam => exam.examName
                  ).join(', ')}{" "}
            </label>
          </div>
        </div>

        {this.state.showExamQuiz
          ? this.props.history.push({
              pathname: `/panel/training/userExam/${this.state.examValue}`,
              state: { examValue: this.state.examValue },
            })
          : null}
      </div>
    );
  }
}
export default withRouter(TrainingPanel);
