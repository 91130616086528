class Crop {
  id;
  name;

  constructor(id, name) {
    this.id = id;
    this.name = name;
  }
}

export default Crop;
