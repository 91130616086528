import React from "react";
import jwt_decode from "jwt-decode";

class ExpiredToken extends React.Component {
  token;
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("token");
  }

  checkTokenExpiration() {
    var decoded = jwt_decode(this.token);
    let expTime = decoded.exp;
    let currentTimestamp = Date.now();
    let currentTime = currentTimestamp.toString().slice(0, -3);
    if (currentTime > expTime) {
      localStorage.clear();
      window.location.href = "/";
    }
  }
}
export default ExpiredToken;
