import React from "react";
import UserDetails from "../models/UserDetails";

class UserDetailsAPI extends React.Component {
  userDetails;
  token;

  constructor(props) {
    super(props);
    this.userDetails = new UserDetails();
    this.token = localStorage.getItem("token");
  }

  async getUserDetails() {
    await fetch(`${process.env.REACT_APP_URL}/settings/personal`, {
      method: "GET",
      headers: {
        authorization: "Bearer " + this.token,
        "cache-control": "no-cache",
        Connection: "keep-alive",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        this.userDetails = response;
      })
      .catch((err) => {
        console.log(err);
      });
    return this.userDetails;
  }

  async getAllUserDetails() {
    await fetch(`${process.env.REACT_APP_URL}/settings/users`, {
      method: "GET",
      headers: {
        authorization: "Bearer " + this.token,
        "cache-control": "no-cache",
        Connection: "keep-alive",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        this.userDetails = response;
      })
      .catch((err) => {
        console.log(err);
      });
    return this.userDetails;
  }

  async updateUserDetails(data) {
    const response = await fetch(
      `${process.env.REACT_APP_URL}/settings/personal`,
      {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          "access-Control-Allow-Origin": "*",
          authorization: "Bearer " + this.token,
        },
        body: JSON.stringify(data),
      }
    );
    let responseJson = await response.json();
    let combinedResponse = { response: response, responseJson: responseJson };
    return combinedResponse;
  }

  async getRoles() {
    await fetch(`${process.env.REACT_APP_URL}/settings/roles`, {
      method: "GET",
      headers: {
        authorization: "Bearer " + this.token,
        "cache-control": "no-cache",
        Connection: "keep-alive",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        return (this.role = response);
      })
      .catch((err) => {
        console.log(err);
      });
    return this.role;
  }

  async createUser(data) {
    const response = await fetch(
      `${process.env.REACT_APP_URL}/settings/users`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          "access-Control-Allow-Origin": "*",
          authorization: "Bearer " + this.token,
        },
        body: JSON.stringify(data),
      }
    );
    let responseJson = await response.json();
    let combinedResponse = { response: response, responseJson: responseJson };
    return combinedResponse;
  }

  //@DELETE
  //Delete User
  async deleteUserById(id) {
    const response = await fetch(
      `${process.env.REACT_APP_URL}/settings/users/${id}`,
      {
        method: "DELETE",
        headers: {
          "access-Control-Allow-Origin": "*",
          authorization: "Bearer " + this.token,
          "cache-control": "no-cache",
          Connection: "keep-alive",
        },
      }
    );
    return response;
  }

  //@PUT
  //Edit user
  async editUser(userId, data) {
    const response = await fetch(
      `${process.env.REACT_APP_URL}/settings/users/${userId}`,
      {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          "access-Control-Allow-Origin": "*",
          authorization: "Bearer " + this.token,
        },
        body: JSON.stringify(data),
      }
    );
    let responseJson = await response.json();
    let combinedResponse = { response: response, responseJson: responseJson };
    return combinedResponse;
  }

  async getCurrentUserDetails(token) {
    await fetch(`${process.env.REACT_APP_URL}/settings/users/signedInUser`, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
        "cache-control": "no-cache",
        Connection: "keep-alive",
      },
    })
      .then((response) => response.json())
      .catch((err) => {
        console.log(err);
      })
      .then((response) => {
        this.userDetails = response;
      })
      .catch((err) => {
        console.log(err);
      });
    return this.userDetails;
  }
  async getAllExistingUsers() {
    return await fetch(
      `${process.env.REACT_APP_URL}/settings/users/getAllExistingUsers`,
      {
        method: "GET",
        headers: {
          authorization: "Bearer " + this.token,
          "cache-control": "no-cache",
          Connection: "keep-alive",
        },
      }
    ).then((response) => response.json());
  }

  //@POST
  //Resend Verification mail to user
  async resendVerificationEmailToUser(data) {
    await fetch(
      `${process.env.REACT_APP_URL}/settings/users/resendverification`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          "access-Control-Allow-Origin": "*",
          authorization: "Bearer " + this.token,
        },
        body: JSON.stringify(data),
      }
    ).then((response) => {
      this.response = response;
    });
    return this.response;
  }



  async updateSmsCommunicationChannel(data) {
    const response = await fetch(
      `${process.env.REACT_APP_URL}/settings/personal/communication/sms?smsSupported=${data}`,
      {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          "access-Control-Allow-Origin": "*",
          authorization: "Bearer " + this.token,
        },
        body: JSON.stringify(data),
      }
    );
    let responseJson = await response.json();
    let combinedResponse = { response: response, responseJson: responseJson };
    return combinedResponse;
  }

  async updateEmailCommunicationChannel(data) {
    const response = await fetch(
      `${process.env.REACT_APP_URL}/settings/personal/communication/email?emailSupported=${data}`,
      {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          "access-Control-Allow-Origin": "*",
          authorization: "Bearer " + this.token,
        },
        body: JSON.stringify(data),
      }
    );
    let responseJson = await response.json();
    let combinedResponse = { response: response, responseJson: responseJson };
    return combinedResponse;
  }

  //update is_on_duty flag for inspection managers
  async updateAvailabiltyStatusOfInspectionManagers(data) {
    const response = await fetch(
      `${process.env.REACT_APP_URL}/settings/personal/availability?isOnDuty=${data}`,
      {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          "access-Control-Allow-Origin": "*",
          authorization: "Bearer " + this.token,
        },
        body: JSON.stringify(data),
      }
    );
    let responseJson = await response.json();
    let combinedResponse = { response: response, responseJson: responseJson };
    return combinedResponse;
  }

  //get list of all inspection managers on duty
  async getOnDutyInspectionManagers() {
    return await fetch(
      `${process.env.REACT_APP_URL}/settings/users/activeInspectionManagers`,
      {
        method: "GET",
        headers: {
          authorization: "Bearer " + this.token,
          "cache-control": "no-cache",
          Connection: "keep-alive",
        },
      }
    ).then((response) => response.json());
  }
}
export default UserDetailsAPI;
