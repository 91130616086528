import React from "react";
import Crop from "../models/Crop";

class CropAPI extends React.Component {
  constructor(props) {
    super(props);
    this.crop = new Crop();
    this.getCrops = this.getCrops.bind(this);
    this.token = localStorage.getItem("token");
  }

  async getCrops() {
    
    await fetch(`${process.env.REACT_APP_URL}/settings/crops`, {
      method: "GET",
      headers: {
        authorization: "Bearer " + this.token,
        "cache-control": "no-cache",
        Connection: "keep-alive",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        this.crop = response;
      })
      .catch((err) => {
        console.log(err);
      });
    return this.crop;
  }
  async getCropsVisibleToUserWithoutValidation() {
    
    await fetch(`${process.env.REACT_APP_URL}/settings/crops/whitelisted`, {
      method: "GET",
      headers: {
        "cache-control": "no-cache",
        Connection: "keep-alive",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        this.crop = response;
      })
      .catch((err) => {
        console.log(err);
      });
    return this.crop;
  }
  async updateVagueFieldCrop(cropNameAndToken) {
    let responseMessage = "";
    await fetch(`${process.env.REACT_APP_URL}/api/fields/updateVagueFieldCrop`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "access-Control-Allow-Origin": "*",
        "cache-control": "no-cache",
        Connection: "keep-alive",
        
      },
      body : JSON.stringify(cropNameAndToken)
    })
      .then((response) => response.text())
      .then((response) => {
        responseMessage = response;
      })
      .catch((err) => {
        responseMessage = "Internal Server Error"
        console.log(err);
      });
    return responseMessage;
  }
}

export default CropAPI;
