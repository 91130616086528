import React, { lazy, Suspense } from "react";
import "./App.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import Swal from "sweetalert2";
import TrainingPanel from "./components/TrainingModule/TrainingPanel";
import UpdateVagueFieldCrop from "./components/UpdateVagueFieldCrop";
import {
  SYSTEM_ADMIN,
  INSPECTION_MANAGER,
  NON_FARM_DASHBOARD,
} from "./Constant";
import UserDetailsAPI from "./api/UserDetailsAPI";
import ExpiredToken from "./components/ExpiredToken";
import { isMobile } from "react-device-detect";

const SidePanel = lazy(() => import("./components/SidePanel"));
const LoginConsole = lazy(() => import("./components/LoginConsole"));
const LoginViaPhoneConsole = lazy(() =>
  import("./components/LoginConsole/LoginViaPhoneConsole")
);
const OTPVerification = lazy(() =>
  import("./components/LoginConsole/OTPVerification")
);
const UserUnsubscribe = lazy(() =>
  import("./components/UserUnsubscribeConsole/UserUnsubscribe")
);
const ForgotPassword = lazy(() =>
  import("./components/ResetPasswordConsole/ForgotPassword")
);
const ResetPassword = lazy(() =>
  import("./components/ResetPasswordConsole/ResetPassword")
);
const Registration = lazy(() =>
  import("./components/RegistrationConsole/RegistrationConsole")
);
const ActivityDetail = lazy(() =>
  import(
    "./components/SidePanel/ActivitesPanel/EditActivityOption/ActivityDetail/ActivityDetail"
  )
);
const ActivitiesPanel = lazy(() =>
  import("./components/SidePanel/ActivitesPanel/ActivitiesPanel")
);
const RegistrationSuccessful = lazy(() =>
  import("./components/RegistrationConsole/RegistrationSuccessful")
);
const ValidationSuccessful = lazy(() =>
  import("./components/RegistrationConsole/ValidationSuccessful")
);
const FrequentlyAskedQuestions = lazy(() =>
  import("./components/SidePanel/FrequentlyAskedQuestions")
);
const AlertsPanel = lazy(() =>
  import("./components/SidePanel/AlertsPanel/AlertsPanel")
);
const PageNotFound = lazy(() => import("./components/PageNotFound"));
const UpdateHerbicide = lazy(() => import("./components/UpdateHerbicide"));

const MailSentSuccessfully = lazy(() =>
  import("./components/MailSentSuccessfully")
);

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.setRouting = this.setRouting.bind(this);
    this.state = {
      redirectToField: false,
      routeToLogin: false,
      redirectToAdmin: false,
      redirectToAccount: false,
      redirectToInspection: false,
      signedInUserRole: "",
      farmName: "",
    };
    this.userDetailsAPI = new UserDetailsAPI();
    this.expiredToken = new ExpiredToken();
  }

  componentDidMount() {
    let token = "";
    const pathName = window.location.pathname;
    token = localStorage.getItem("token");
    if (token !== null && pathName === "/") {
      this.setRouting(token);
    }
    // Change title dynamically based on environment type
    document.title = process.env.REACT_APP_WEB_TITLE;
  }

  setRouting(token) {
    this.expiredToken.checkTokenExpiration();
    this.userDetailsAPI.getCurrentUserDetails(token).then((response) => {
      if (response.status !== "BAD_REQUEST") {
        if (typeof response !== "undefined") {
          this.setState({
            farmName: response.farmDetails
              ? response.farmDetails.farmName
              : NON_FARM_DASHBOARD,
          });
          if (response.farmDetails !== null) {
            this.setState({ redirectToField: true });
          } else if (response.userAndRolesData !== null) {
            Object.values(response.userAndRolesData).forEach((user) => {
              if (user.role === null) {
                this.setState({ redirectToAccount: true });
              } else if (
                user.role.name === SYSTEM_ADMIN
              ) {
                this.setState({ redirectToAdmin: true });
              } else if (
                user.role.name === INSPECTION_MANAGER
              ) {
                this.setState({ redirectToInspection: true });
              }
              this.setState({ signedInUserRole: user.role.name });
            });
          }
        }
      }
    });
  }

  Loader = () => {
    return (
      <>
        {Swal.showLoading()}
        {Swal.close()}
      </>
    );
  };

  render() {
    return (
      <React.Fragment>
        {localStorage.getItem("token") ? (
          <BrowserRouter>
            <Suspense fallback={this.Loader}>
              <Switch>
                <Route exact path="/">
                  <LoginViaPhoneConsole />
                </Route>
                <Route exact path="/login/:phone">
                  <LoginViaPhoneConsole />
                </Route>
                <Route exact path="/loginByEmail">
                  <LoginConsole />
                </Route>
                <Route exact path="/verifyOTP">
                  <OTPVerification />
                </Route>
                <Route exact path="/registration">
                  <Registration />
                </Route>
                <Route exact path="/registration/verify">
                  <RegistrationSuccessful />
                </Route>
                <Route exact path="/api/verifiedUser/:email">
                  <ValidationSuccessful />
                </Route>
                {/* <Route exact path="/sidepanel"><SidePanel /></Route> */}
                <Route path="/panel">
                  <SidePanel
                    signedInUserRole={this.state.signedInUserRole}
                    farmName={this.state.farmName}
                  />
                </Route>
                <Route exact path="/forgotpassword">
                  <ForgotPassword />
                </Route>
                <Route exact path="/unsubscribe">
                  <UserUnsubscribe />
                </Route>
                <Route exact path="/resetpassword">
                  <ResetPassword />
                </Route>
                <Route exact path="/api/forgotPassword/validate/:token">
                  <ResetPassword />
                </Route>
                <Route exact path="/panel/faq">
                  <FrequentlyAskedQuestions />
                </Route>
                <Route exact path="/activityDetail/:id">
                  <ActivityDetail />
                </Route>
                <Route exact path="/panel/activities">
                  <ActivitiesPanel />{" "}
                </Route>
                <Route exact path="/panel/alerts">
                  <AlertsPanel />{" "}
                </Route>
                <Route exact path="/panel/training">
                  <TrainingPanel />{" "}
                </Route>
                <Route exact path="/mailSent">
                  <MailSentSuccessfully />
                </Route>
                <Route exact path="/updateHerbicide/:token">
                  <UpdateHerbicide />
                </Route>
                <Route exact path="/updateVagueFieldCrop/:token">
                  <UpdateVagueFieldCrop />{" "}
                </Route>
              </Switch>
              {this.state.redirectToField && <Redirect to= {isMobile ? "/panel/landing" : "/panel/fields"} />}
              {this.state.redirectToAdmin && <Redirect to="/panel" />}
              {this.state.redirectToAccount && <Redirect to="/panel/account" />}
              {this.state.redirectToInspection && <Redirect to="/panel/inspection" />}
            </Suspense>
          </BrowserRouter>
        ) : (
          <BrowserRouter>
            <Suspense fallback={<this.Loader />}>
              <Switch>
                <Route exact path="/">
                  <LoginViaPhoneConsole />
                </Route>
                <Route exact path="/login/:phone">
                  <LoginViaPhoneConsole />
                </Route>
                <Route exact path="/loginByEmail">
                  <LoginConsole />
                </Route>
                <Route exact path="/verifyOTP">
                  <OTPVerification />
                </Route>
                <Route exact path="/registration">
                  <Registration />
                </Route>
                <Route exact path="/registration/verify">
                  <RegistrationSuccessful />
                </Route>
                <Route exact path="/api/verifiedUser/:email">
                  <ValidationSuccessful />
                </Route>
                <Route exact path="/mailSent">
                  <MailSentSuccessfully />
                </Route>
                <Route exact path="/updateHerbicide/:token">
                  <UpdateHerbicide />
                </Route>
                {/* <Route exact path="/sidepanel"><SidePanel /></Route> */}
                <Route exact path="/forgotpassword">
                  <ForgotPassword />
                </Route>
                <Route exact path="/unsubscribe">
                  <UserUnsubscribe />
                </Route>
                <Route path="/panel">
                  <SidePanel />
                </Route>
                <Route exact path="/panel/faq">
                  <FrequentlyAskedQuestions />
                </Route>
                <Route exact path="/resetpassword">
                  <ResetPassword />
                </Route>
                <Route path="/panel">
                  <SidePanel />
                </Route>
                <Route exact path="/api/forgotPassword/validate/:token">
                  <ResetPassword />
                </Route>
                <Route exact path="*">
                  <PageNotFound />
                </Route>
                <Route exact path="/activityDetail/:id">
                  <ActivityDetail />
                </Route>
                <Route exact path="/panel/activities">
                  <ActivitiesPanel />{" "}
                </Route>
                <Route exact path="/panel/alerts">
                  <AlertsPanel />{" "}
                </Route>
                <Route exact path="/panel/training">
                  <TrainingPanel />{" "}
                </Route>
                <Route exact path="/updateVagueFieldCrop/:token">
                  <UpdateVagueFieldCrop />{" "}
                </Route>
              </Switch>
            </Suspense>
            {this.state.redirectToField && <Redirect to="/panel/fields" />}
          </BrowserRouter>
        )}
        <CssBaseline></CssBaseline>
      </React.Fragment>
    );
  }
}
